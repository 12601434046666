import * as axios from 'axios';
import router from '../../router';

export const state = {
  loginErr: false,
  noEmail: false,
  resetEmailSent: false
};

export const actions = {
  login_user ({ rootState, state }, obj) {
    axios
      .post('/api/login', obj)
      .then(({ data }) => {
        rootState.user = {
          name: data.name,
          rol: data.roles[0]
        };
        state.loginErr = false;
        router.push('/pacienti');
      })
      // eslint-disable-next-line
      .catch((err) => {
        state.loginErr = true;
      });
  },

  checkLogin ({ rootState, state }, { to, next }) {
    axios('/api/check-login')
      .then((resp) => {
        const data = resp.data.session;
        if (data.userCtx && data.userCtx.name) {
          rootState.user = {
            nume: resp.data.user.nume,
            functie: resp.data.user.functie,
            email: resp.data.user.email,
            telefon: resp.data.user.telefon,
            name: data.userCtx.name,
            rol: data.userCtx.roles[0]
          };
          rootState.isLogged = true;
        }
        data.userCtx && data.userCtx.name ? next() : next('/login');
      })
      .catch((e) => {
        next('/login');
        console.log(e);
      });
  },

  logout ({ rootState }) {
    axios('/api/logout')
      .then(({ data }) => {
        rootState.isLogged = false;
        rootState.user = {};
        state.user = {};
        router.push('/login');
      })
      .catch((err) => {
        console.log(err);
      });
  },

  forgot_pass ({ state, rootState }, email) {
    send();

    async function send () {
      try {
        rootState.isLoading = true;
        const { data } = await axios(`/api/forgot-pass/${email}`);
        state.resetEmailSent = true;
        rootState.isLoading = false;
      } catch (e) {
        state.loginErr = true;
        state.noEmail = true;
        rootState.isLoading = false;
        console.log('error forgot pass', e.response.data);
      }
    }
  },

  save_new_pass ({ state, commit }, payload) {
    save();

    async function save () {
      try {
        const { data } = await axios.post('/api/save-new-pass', payload);
        commit('RESET_LOGIN_ERR');
        const route = await router.push('/login');
        payload.clbk();
      } catch (e) {
        console.log(e.response.data);
      }
    }
  }
};

export const mutations = {
  RESET_LOGIN_ERR (state) {
    state.loginErr = false;
    state.noEmail = false;
  }
};
