import { svg } from './svg';
import { nextDate } from './utils';
import { tratamente } from './tratamente';

export const state = {
  user: {},
  searchResultsPerPage: 10,
  searchPagination: false,
  pacientiSearchResults: [],
  filter: {
    isFilterPatients: false,
    payload: {},
    bookmarks: []
  },
  isExistingUser: false,
  svg,
  roluri: ['medic', 'asistent', 'admin'],
  isClickModal: false,
  isLoading: false,
  isUpdateModal: false,
  modalData: {
    type: undefined,
    createdAt: new Date(),
    pics: [],
    paid: ''
  },
  modalProgramare: {
    createdAt: new Date(new Date().setDate(new Date().getDate() + 1)),
    trimite_sms: true,
    deLa: { ora: '', minute: '' },
    panaLa: { ora: '', minute: '' }
  },
  formData: new FormData(),
  modelTratament: {
    type: 'tratament',
    createdAt: new Date(),
    dinte: undefined,
    denumire: undefined,
    detalii: undefined
  },
  isLogged: false,
  isEditPacient: false,
  orase: [],
  judete: [],
  tari: [
    'Afghanistan',
    'Albania',
    'Algeria',
    'Andorra',
    'Angola',
    'Antigua and Barbuda',
    'Argentina',
    'Armenia',
    'Australia',
    'Austria',
    'Azerbaijan',
    'The Bahamas',
    'Bahrain',
    'Bangladesh',
    'Barbados',
    'Belarus',
    'Belgium',
    'Belize',
    'Benin',
    'Bhutan',
    'Bolivia',
    'Bosnia and Herzegovina',
    'Botswana',
    'Brazil',
    'Brunei',
    'Bulgaria',
    'Burkina Faso',
    'Burundi',
    'Cabo Verde',
    'Cambodia',
    'Cameroon',
    'Canada',
    'Central African Republic',
    'Chad',
    'Chile',
    'China',
    'Colombia',
    'Comoros',
    'Congo, Democratic Republic of the',
    'Congo, Republic of the',
    'Costa Rica',
    'Côte d’Ivoire',
    'Croatia',
    'Cuba',
    'Cyprus',
    'Czech Republic',
    'Denmark',
    'Djibouti',
    'Dominica',
    'Dominican Republic',
    'East Timor (Timor-Leste)',
    'Ecuador',
    'Egypt',
    'El Salvador',
    'Equatorial Guinea',
    'Eritrea',
    'Estonia',
    'Eswatini',
    'Ethiopia',
    'Fiji',
    'Finland',
    'France',
    'Gabon',
    'The Gambia',
    'Georgia',
    'Germany',
    'Ghana',
    'Greece',
    'Grenada',
    'Guatemala',
    'Guinea',
    'Guinea-Bissau',
    'Guyana',
    'Haiti',
    'Honduras',
    'Hungary',
    'Iceland',
    'India',
    'Indonesia',
    'Iran',
    'Iraq',
    'Ireland',
    'Israel',
    'Italy',
    'Jamaica',
    'Japan',
    'Jordan',
    'Kazakhstan',
    'Kenya',
    'Kiribati',
    'Korea, North',
    'Korea, South',
    'Kosovo',
    'Kuwait',
    'Kyrgyzstan',
    'Laos',
    'Latvia',
    'Lebanon',
    'Lesotho',
    'Liberia',
    'Libya',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Madagascar',
    'Malawi',
    'Malaysia',
    'Maldives',
    'Mali',
    'Malta',
    'Marshall Islands',
    'Mauritania',
    'Mauritius',
    'Mexico',
    'Micronesia, Federated States of',
    'Moldova',
    'Monaco',
    'Mongolia',
    'Montenegro',
    'Morocco',
    'Mozambique',
    'Myanmar (Burma)',
    'Namibia',
    'Nauru',
    'Nepal',
    'Netherlands',
    'New Zealand',
    'Nicaragua',
    'Niger',
    'Nigeria',
    'North Macedonia',
    'Norway',
    'Oman',
    'Pakistan',
    'Palau',
    'Panama',
    'Papua New Guinea',
    'Paraguay',
    'Peru',
    'Philippines',
    'Poland',
    'Portugal',
    'Qatar',
    'Romania',
    'Russia',
    'Rwanda',
    'Saint Kitts and Nevis',
    'Saint Lucia',
    'Saint Vincent and the Grenadines',
    'Samoa',
    'San Marino',
    'Sao Tome and Principe',
    'Saudi Arabia',
    'Senegal',
    'Serbia',
    'Seychelles',
    'Sierra Leone',
    'Singapore',
    'Slovakia',
    'Slovenia',
    'Solomon Islands',
    'Somalia',
    'South Africa',
    'Spain',
    'Sri Lanka',
    'Sudan',
    'Sudan, South',
    'Suriname',
    'Sweden',
    'Switzerland',
    'Syria',
    'Taiwan',
    'Tajikistan',
    'Tanzania',
    'Thailand',
    'Togo',
    'Tonga',
    'Trinidad and Tobago',
    'Tunisia',
    'Turkey',
    'Turkmenistan',
    'Tuvalu',
    'Uganda',
    'Ukraine',
    'United Arab Emirates',
    'United Kingdom',
    'United States',
    'Uruguay',
    'Uzbekistan',
    'Vanuatu',
    'Vatican City',
    'Venezuela',
    'Vietnam',
    'Yemen',
    'Zambia',
    'Zimbabwe'
  ],
  medici: [],
  tratamente,
  ddClone: undefined,
  pacienti: [],
  utilizatori: [],
  pacientiClone: undefined,
  pacient: {
    history: [],
    _attachments: {},
    varsta: ''
  },
  antecedente: false,
  alergii: false,
  modalComponentName: '',
  isModal: false,
  isEditHistory: false,
  page: 0,
  bookmarks: [],
  lastPage: false,
  numeDinte: undefined,
  dinteElm: undefined,
  defaults: {
    opacity: '0.1'
  },
  disableDintiDD: false,
  printComponentName: 'fisaPacient',
  // printComponentName: 'scrisoareMedicala',
  textScrisoare: '',
  isEditScrisoare: false,
  indexScrisoare: undefined,
  avatarPic: undefined,
  dinti: [
    'molar 1.8',
    'molar 1.7',
    'molar 1.6',
    'molar 2.6',
    'molar 2.7',
    'molar 2.8',
    'molar 4.8',
    'molar 4.7',
    'molar 4.6',
    'molar 3.6',
    'molar 3.7',
    'molar 3.8',
    'd.temp 5.1',
    'd.temp 5.2',
    'd.temp 5.3',
    'd.temp 5.4',
    'd.temp 5.5',
    'd.temp 6.1',
    'd.temp 6.2',
    'd.temp 6.3',
    'd.temp 6.4',
    'd.temp 6.5',
    'd.temp 7.1',
    'd.temp 7.2',
    'd.temp 7.3',
    'd.temp 7.4',
    'd.temp 7.5',
    'd.temp 8.1',
    'd.temp 8.2',
    'd.temp 8.3',
    'd.temp 8.4',
    'd.temp 8.5',
    'premolar 1.5',
    'premolar 1.4',
    'premolar 2.4',
    'premolar 2.5',
    'premolar 4.4',
    'premolar 4.5',
    'premolar 3.4',
    'premolar 3.5',
    'canin 1.3',
    'canin 2.3',
    'canin 4.3',
    'canin 3.3',
    'incisiv 1.2',
    'incisiv 1.1',
    'incisiv 2.1',
    'incisiv 2.2',
    'incisiv 4.2',
    'incisiv 4.1',
    'incisiv 3.1',
    'incisiv 3.2'
  ],
  dantura: {
    top: [
      'molar-1.8',
      'molar-1.7',
      'molar-1.6',
      'premolar-1.5',
      'premolar-1.4',
      'canin-1.3',
      'incisiv-1.2',
      'incisiv-1.1',
      'incisiv-2.1',
      'incisiv-2.2',
      'canin-2.3',
      'premolar-2.4',
      'premolar-2.5',
      'molar-2.6',
      'molar-2.7',
      'molar-2.8'
    ],
    bottom: [
      'molar-4.8',
      'molar-4.7',
      'molar-4.6',
      'premolar-4.5',
      'premolar-4.4',
      'canin-4.3',
      'incisiv-4.2',
      'incisiv-4.1',
      'incisiv-3.1',
      'incisiv-3.2',
      'canin-3.3',
      'premolar-3.4',
      'premolar-3.5',
      'molar-3.6',
      'molar-3.7',
      'molar-3.8'
    ]
  },
  dintiDestinatie: [],
  dintiTratamentCoroana: [],
  filterTratamente: undefined,
  punteSide: undefined,
  punteDirection: undefined,
  confirmationMsg: undefined,
  confirmationSubject: undefined,
  cloneHistory: undefined,
  totalPacienti: 0,
  programari: []
};
