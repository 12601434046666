/* sharing the functionality of BaseTerms component, with forms from Incorporate,
 * Modify and Cancel pages */
import moment from 'moment';
import { ro } from 'vuejs-datepicker/dist/locale';
moment.locale('ro');
export const historyModal = {
  data () {
    return {
      disabledDates: {
        to: new Date(moment(new Date()).format('MM-DD-YYYY'))
      },
      ro,
      isDelete: false
    };
  },
  methods: {
    _clickModal () {
      this.$store.commit('SET_KEY_VALUE', { key: 'isClickModal', value: true });
    },

    _customFormatter (date) {
      return moment(date).format('Do MMM YYYY') + '';
    },

    _selectImg ({ file, picName }) {
      this.$store.commit('ADD_IMGS_TO_MODAL', { file, picName });
    },

    _setDDvalue ({ name, value }) {
      const obj = {
        key: 'modalData',
        sub: name,
        value
      };
      this.$store.commit('SET_KEY_VALUE', obj);
    },

    _checkDelete (val) {
      this.isDelete = val;
    },

    _deleteTratament () {
      this.isDelete = true;
      const obj = {
        id: this.$router.currentRoute.params.id
      };
      this.$store.dispatch('delete_tratament', obj);
    }
  }
};
