export const tratamente = [
  { name: 'Reevaluare si igienizare parodontala', pret: '650' },
  { name: 'Evaluare Parodontala ', pret: '330' },
  { name: 'Tratament parodontal chirurigical', pret: '330' },
  { name: 'Tratament parodontal non-chirugical', pret: '170' },
  { name: 'Tratament parodontal non-chirugical laser', pret: '230' },
  { name: 'Consultatie simpla', pret: '150' },
  { name: 'Consultatie medic specialist', pret: '200' },
  { name: 'Pachet consultatie estetica', pret: '450' },
  { name: 'Pachet consultatie implant', pret: '550' },
  { name: 'Pachet consultatie reabilitate completa', pret: '800' },
  { name: 'Control periodic', pret: '150' },
  { name: 'Radiografie retroalvolara digitala', pret: '60' },
  { name: 'Radiografie panoramica digitala OPG', pret: '100' },
  { name: '3D CT Dentar partial', pret: '250' },
  { name: '3D CT Dentar total', pret: '400' },
  { name: 'Igienizare profesionala GBT', pret: '450' },
  { name: 'Igienizare profesionala subgingivala GBT', pret: '650' },
  { name: 'Igienizare profesionala GBT - 6 luni', pret: '360' },
  { name: 'Obturatie dinte lateral  Waterlase', pret: '500' },
  { name: 'Obturatie dinte frontal  Waterlase', pret: '600' },
  { name: 'Gingivectomie laser WaterLase', pret: '400' },
  { name: 'Alungire coronara laser WaterLase', pret: '660' },
  { name: 'Frenectomie laser WaterLase', pret: '750' },
  { name: 'Incizie & drenaj abces  WaterLase', pret: '400' },
  { name: 'Descoperire implant  WaterLase', pret: '400' },
  { name: 'Mini implant ortodontic', pret: '850' },
  { name: 'Obturatie colet cls.V', pret: '350' },
  { name: 'Obturatie dinte frontal cls.III', pret: '350' },
  { name: 'Obturatie dinte frontal cls.IV', pret: '450' },
  { name: 'Obturatie dinte frontal cls.IV + incizal', pret: '550' },
  { name: 'Obturatie estetica frontala vestibulara', pret: '500' },
  { name: 'Obturatie dinte posterior cls.I', pret: '350' },
  { name: 'Obturatie dinte posterior cls.II-a cu 1 suprafata', pret: '350' },
  { name: 'Obturatie dinte posterior cls.II-a cu 2 suprafate', pret: '450' },
  { name: 'Obturatie dinte posterior cls.II-a cu 3 suprafate', pret: '600' },
  { name: 'Obturatie dinte posterior cls.II-a cu 4 suprafate', pret: '800' },
  { name: 'Obturatie sub microscop', pret: '550' },
  { name: 'Pansament calmant  urgenta', pret: '250' },
  { name: 'Obturatie provizorie', pret: '70' },
  { name: 'Model studiu + plan tratament', pret: '350' },
  { name: 'Tratament ortodontic Aparat dentar fix metalic', pret: '3950' },
  { name: 'Tratament ortodontic Aparat dentar fix estetic', pret: '5450' },
  { name: 'Tratament ortodontic Aparat dentar mobil', pret: '1800' },
  { name: 'Tratament ortodontic CLEAR CORRECT UNLIMITED (full)', pret: '21000' },
  { name: 'Tratament ortodontic CLEAR CORRECT TWO', pret: '18500' },
  { name: 'Tratament ortodontic CLEAR CORRECT ONE', pret: '16000' },
  { name: 'Tratament ortodontic CLEAR CORRECT MINI', pret: '7400' },
  { name: 'Tratament ortodontic CLEAR ALIGNER 1', pret: '4200' },
  { name: 'Tratament ortodontic CLEAR ALIGNER 2', pret: '5900' },
  { name: 'Tratament ortodontic CLEAR ALIGNER FULL1', pret: '9800' },
  { name: 'Tratament ortodontic CLEAR ALIGNER FULL2', pret: '12000' },
  { name: 'Tratament ortodontic SPARK10 SINGLE', pret: '5900' },
  { name: 'Tratament ortodontic SPARK10 DOUBLE', pret: '7600' },
  { name: 'Tratament ortodontic SPARK20 SINGLE', pret: '12000' },
  { name: 'Tratament ortodontic SPARK20 DOUBLE', pret: '16000' },
  { name: 'Tratament ortodontic SPARK FULL SINGLE', pret: '17600' },
  { name: 'Tratament ortodontic SPARK FULL DOUBLE', pret: '21000' },
  { name: 'Control + activare aparat', pret: '170' },
  { name: 'Control + activare aparat 2 arcade', pret: '220' },
  { name: 'Bracket metalic desprins', pret: '250' },
  { name: 'Bracket estetic desprins', pret: '300' },
  { name: 'Expansor maxilar', pret: '2200' },
  { name: 'Trainer silicon', pret: '1700' },
  { name: 'Mentinator spatiu', pret: '1000' },
  { name: 'Tratament ortodontic gutiere Clear Aligner', pret: '4200' },
  { name: 'Indepartare aparat ortodontic fix', pret: '400' },
  { name: 'Albire dentara profesionala', pret: '1350' },
  { name: 'Albire dentara laser', pret: '1700' },
  { name: 'Amprenta digitala 3D', pret: '600' },
  { name: 'Digital Smile Design / dinte', pret: '220' },
  {
    name: 'Fateta ceramica premium feldspatica',
    color: '#4fcfb1',
    dinteDD: true,
    id: 1.2,
    pret: '2500'
  },
  {
    name: 'Fateta ceramica premium feldspatica NONPREP',
    color: '#4fcfb1',
    dinteDD: true,
    id: 1.2,
    pret: '2750'
  },
  {
    name: 'Fateta ceramica premium Ambermill',
    color: '#4fcfb1',
    dinteDD: true,
    id: 1.2,
    pret: '2250'
  },
  { name: 'Coroana ceramica premium felspatica', pret: '2500' },
  {
    name: 'Coroana ceramica premium feldspatica NONPREP',
    color: '#4fcfb1',
    dinteDD: true,
    id: 1.2,
    pret: '2750'
  },
  {
    name: 'Coroana ceramica premium Ammbermill',
    dinteDD: true,
    id: 1.1,
    pret: '2250'
  },
  { name: 'Coroana ceramica suport metal', pret: '1500' },
  {
    name: 'Inlay/onlay ceramica premium CEREC',
    color: '#4fcfb1',
    dinteDD: true,
    id: 1.3,
    pret: '1700'
  },
  {
    name: 'Coroana provizorie cabinet',
    color: '#a9e8e6',
    dinteDD: true,
    id: 1.4,
    pret: '200'
  },
  {
    name: 'Coroana provizorie Telio',
    color: '#a9e8e6',
    dinteDD: true,
    id: 1.4,
    pret: '400'
  },
  {
    name: 'Coroana provizorie Telio implant',
    color: '#a9e8e6',
    dinteDD: true,
    id: 1.4,
    pret: '900'
  },
  { name: 'Wax-up / dinte', pret: '220' },
  { name: 'Bont protetic custom zirconiu / implant premium Megagen', pret: '1400' },
  { name: 'Bont protetic custom zirconiu / implant premmium Straumann', pret: '1550' },
  { name: 'Bont protetic std titan / implant Megagen', pret: '1100' },
  { name: 'Bont protetic std titan / implant Straumann', pret: '1200' },
  {
    name: 'Lucrare protetica finala ALL ON 4/6 metalo-ceramica',
    dinteDD: true,
    id: 1.5,
    pret: '14900'
  },
  {
    name: 'Lucrare protetica finala ALL ON 4/6 premium titan',
    dinteDD: true,
    id: 1.5,
    pret: '24500'
  },
  {
    name: 'Lucrare protetica finala premium ALL ON 4/6 full zirconiu',
    dinteDD: true,
    id: 1.1,
    pret: '27500'
  },
  { name: 'Reconstructie bonturi cu pivot fibra sticla', pret: '400' },
  { name: 'Sistem fixare multi-unit ALL on 4/6', pret: '750' },
  { name: 'Ablatie coroana zirconiu/emax', pret: '280' },
  { name: 'Ablatie coroana metalo-ceramica', pret: '230' },
  { name: 'Proteza Kemeny', pret: '800' },
  { name: 'Gutiera contentie bruxism', pret: '400' },
  { name: 'Gutiera contentie premium', pret: '550' },
  { name: 'Proteza totala premium', pret: '2400' },
  { name: 'Proteza partiala premium', pret: '2100' },
  { name: 'Proteza scheletata', pret: '5500' },
  { name: 'Cimentare', pret: '200' },
  { name: 'Coroana ceramica cerec', dinteDD: true, id: 1.1, pret: '2200' },
  { name: 'Endocrown cerec', dinteDD: true, id: 1.1, pret: '2500' },
  { name: 'Inlay/onlay ceramica cerec', pret: '1700' },
  { name: 'Tratament endo microscop - vital  incisiv', pret: '700' },
  { name: 'Tratament endo microscop - vital  premolar', pret: '900' },
  { name: 'Tratament endo microscop - vital  molar', pret: '1150' },
  { name: 'Retratament endo microscop - devital  incisiv', pret: '800' },
  { name: 'Retratament endo microscop - devital  premolar', pret: '950' },
  { name: 'Retratament endo microscop - devital  molar', pret: '1300' },
  { name: 'Indepartare instrument fracturat', pret: '250' },
  { name: 'Ablatie Dentatus / DCR', pret: '220' },
  { name: 'Aplicare MTA', pret: '250' },
  {
    name: 'Implant dentar Megagen Anyone / MIS Seven ',
    dinteDD: true,
    id: 4,
    pret: '2950'
  },
  {
    name: 'Implant dentar premium Megagen Anyridge',
    dinteDD: true,
    id: 4,
    pret: '4250'
  },
  {
    name: 'Implant dentar premium Straumann BLX SLA Active',
    dinteDD: true,
    id: 4,
    pret: '4990'
  },
  {
    name: 'One day implant Megagen Anyone',
    dinteDD: true,
    id: 4,
    pret: '4100'
  },
  {
    name: 'One day implant premium Megagen Anyridge',
    dinteDD: true,
    id: 4,
    pret: '5400'
  },
  { name: 'ALL on 4 - 4 implanturi dentare Megagen Anyone / MIS Seven', pret: '15900' },
  { name: 'ALL on 4 - 4 implanturi dentare premium Megagen Anyridge', pret: '19500' },
  { name: 'ALL on 4 - 6 implanturi dentare premium Straumann BLX', pret: '23500' },
  { name: 'ALL on 6 - 6 implanturi dentare Megagen Anyone / MIS Seven', pret: '19500' },
  { name: 'ALL on 6 - 6 implanturi dentare premium Megagen Anyridge', pret: '25000' },
  { name: 'ALL on 6 - 6 implanturi dentare premium Straumann BLX', pret: '28000' },
  { name: 'Starconcept - 4 implanturi dentare premium Megagen Anyridge', pret: '26000' },
  { name: 'Starconcept - 6 implanturi dentare premium Megagen Anyridge', pret: '30000' },
  { name: 'Starconcept - 8 implanturi dentare premium Megagen Anyridge', pret: '35000' },
  { name: 'Starconcept - 4 implanturi dentare premium Straumann BLX SLA Active', pret: '28500' },
  { name: 'Starconcept - 6 implanturi dentare premium Straumann BLX SLA Active', pret: '34500' },
  { name: 'Starconcept - 8 implanturi dentare premium Straumann BLX SLA Active', pret: '39500' },
  { name: 'Ghid chirurgical implant 1/2 gauri', pret: '660' },
  { name: 'Ghid chirurgical implant 3/4 gauri', pret: '1000' },
  { name: 'Ghid chirurgical implant arcada', pret: '1500' },
  { name: 'Aditie osoasa 1-2 dinti', pret: '2750' },
  { name: 'Aditie osoasa 3/- dinti', pret: '3750' },
  { name: 'Aditie osoasa extinsa', pret: '5200' },
  { name: 'Sinus lift extern', pret: '5900' },
  { name: 'Sinus lift intern', pret: '2700' },
  { name: 'Augumentare gingivala extinsa', pret: '3500' },
  { name: 'Augumentare gingivala 2/3 dinti', pret: '2750' },
  { name: 'Augumentare gingivala 1 dinte', pret: '2200' },
  { name: 'Anestezie digitala STA', pret: '120' },
  { name: 'Extractie dinte monoradicular', dinteDD: true, id: 3, pret: '350' },
  { name: 'Extractie dinte pluriradicular', dinteDD: true, id: 3, pret: '400' },
  { name: 'Extractie rest radicular', pret: '450' },
  { name: 'Extractie molar minte erupt complet', pret: '600' },
  { name: 'Extractie molar minte erupt partial', pret: '800' },
  { name: 'Extractie molar minte inclus', pret: '1100' },
  { name: 'Investigatie exploratorie endodontica cu microscop Zeiss ', pret: '200' },
  { name: 'Frenectomie WaterLase', pret: '750' },
  { name: 'Incizie / drenaj abces dentar  WaterLase', pret: '400' },
  { name: '[K] Consultatie complexa medic specialist pedodont copii <3ani', pret: '150' },
  { name: '[K] Consultatie complexa medic specialist pedodont', pret: '180' },
  { name: '[K] Control periodic', pret: '120' },
  { name: '[K] Conisliere psihologica părinți și copii / ora', pret: '150' },
  { name: '[K] Tratament de urgenta dentitie temporara', pret: '220' },
  { name: '[K] Tratament de urgenta dentitie permanenta', pret: '330' },
  { name: '[K] Sedinta acomodare pacient necooperant', pret: '120' },
  { name: '[K] DT - Obturatie ciment glassionomer 1 suprafata', pret: '180' },
  { name: '[K] DT - Obturatie ciment glassionomer 2 suprafete', pret: '200' },
  { name: '[K] DT - Obturatie ciment glassionomer 3 suprafete', pret: '230' },
  { name: '[K] DT - Obturatie compozit 1 suprafata', pret: '180' },
  { name: '[K] DT - Obturatie compozit 2 suprafete', pret: '200' },
  { name: '[K] DT - Obturatie compozit 3 suprafete', pret: '230' },
  { name: '[K] DT - Obturatie compozit colorat', pret: '180' },
  { name: '[K] DT - Obturatie provizorie', pret: '90' },
  { name: '[K] DT - Baza obturatie', pret: '100' },
  { name: '[K] DT - Aplicare SDF', pret: '100' },
  { name: '[K] DP - Tratament ICON', pret: '200' },
  { name: '[K] DP - Coafaj', pret: '140' },
  { name: '[K] DP - Coafaj cu Biodentine', pret: '190' },
  { name: '[K] DP - Baza obturatie', pret: '130' },
  { name: '[K] DP - Obturatie ciment gassionomer 1 suprafata', pret: '220' },
  { name: '[K] DP - Obturatie ciment gassionomer 2 suprafete ', pret: '250' },
  { name: '[K] DP - Obturatie ciment gassionomer x3 suprafete ', pret: '280' },
  { name: '[K] DP - Obturatie compozit 1 suprafata', pret: '230' },
  { name: '[K] DP - Obturatie compozit 2 suprafete ', pret: '270' },
  { name: '[K] DP - bturatie compozit 3 suprafete', pret: '300' },
  { name: '[K] DP - Obturatie provizorie', pret: '90' },
  { name: '[K] DT - Pulpotomie vitala', pret: '180' },
  { name: '[K] DT - Pulpectomie vitala dinte monoradicular', pret: '140' },
  { name: '[K] DT - Pulpectomie vitala dinte pluriradicular', pret: '180' },
  { name: '[K] DT - Trarament devitalizant', pret: '140' },
  { name: '[K] DT - Pansament calmant + obturatie provizorie', pret: '120' },
  { name: '[K] DT - Tratament medicamentos/sedinta + obturatie provizorie', pret: '120' },
  { name: '[K] DT - Trarament necroza / gangrena pulpara', pret: '170' },
  { name: '[K] DT - Obturatie de canal dinte monoradicular', pret: '150' },
  { name: '[K] DT - Obturatie de canal dinte pluriradicular', pret: '180' },
  { name: '[K] DT - Drenaj ', pret: '120' },
  { name: '[K] DT - Tratament cu Biodentine ', pret: '180' },
  {
    name: '[K] DT - Extractie dinte mobil',
    dinteDD: true,
    id: 3,
    pret: '120'
  },
  {
    name: '[K] DT Extractie dinte temporar fara mobilitate',
    dinteDD: true,
    id: 3,
    pret: '170'
  },
  { name: '[K] DT - Extractie rest radicular ', pret: '170' },
  { name: '[K] DT - Curatare cavitate laser ', pret: '200' },
  { name: '[K] DT - Gingivectomie laser ', pret: '300' },
  { name: '[K] DT - Decapusonare laser ', pret: '350' },
  { name: '[K] DT - Frenectomie laser ', pret: '600' },
  {
    name: '[K] DT - Extractie dinte in scop ortodontic',
    dinteDD: true,
    id: 3,
    pret: '170'
  },
  {
    name: '[K] DT - Imobilizare dinte post traumatism',
    dinteDD: true,
    id: 3,
    pret: '280'
  },

  {
    name: '[K] DT - Coroana metalica',
    color: '#a9e8e6',
    dinteDD: true,
    id: 1.4,
    pret: '330'
  },
  { name: '[K] DT - Coroana Zirconiu', dinteDD: true, id: 1.1, pret: '500' },
  { name: '[K] Mentinator de spatiu metalic', pret: '550' },
  { name: '[K] Imobilizare dinte post traumatic', pret: '220' },
  {
    name: '[K] Model de studiu',
    dinteDD: true,
    id: 1.1,
    pret: '120'
  },

  { name: '[K] DT Periaj+flourizare+instructaj', pret: '180' },
  { name: '[K] DT Periaj', pret: '100' },
  { name: '[K] DT Pachet igienizare', pret: '250' },
  { name: '[K] DM Pachet igienizare ', pret: '300' },
  { name: '[K] DP Pachet igienizare', pret: '350' },
  { name: '[K] Flouorizare topica', pret: '120' },
  { name: '[K] Sigilare dinte temporar', pret: '140' },
  { name: '[K] Obturatie preventiva dinte temporar', pret: '160' },
  { name: '[K] Sigilare dinte permant', pret: '170' },
  { name: '[K] Obturatie preventiva dinte permanent', pret: '200' },
  { name: '[K] Refacere sigilare', pret: '100' },
  { name: '[K] Determinare indice de placa', pret: '50' },

  {
    name: 'Coroana metalo-ceramica pe implant',
    dinteDD: true,
    id: 1.5
  },
  {
    name: 'Punte ceramica / Zirconiu',
    fill: '#34a186',
    dinteDD: true,
    id: 6.1
  },
  {
    name: 'Punte acrilica provizorie',
    fill: '#a9e8e6',
    dinteDD: true,
    id: 6.4
  },
  {
    name: 'Punte metalo-ceramica',
    fill: '#FFB400',
    dinteDD: true,
    id: 6.5
  },
  { name: 'Indepartare expansor', pret: '200' },
  { name: 'Tratament de urgenta', pret: '500' },
  { name: 'Activare aparat estetic', pret: '200' },
  { name: 'Activare aparat mobil', pret: '120' },
  { name: 'Rezectie apicala', pret: '2250' },
  { name: 'Extractie cu split paro/dinte', pret: '400' },
  { name: 'Chiuretaj postextractional laser/dinte', pret: '200' },
  { name: 'Igienizare lucrări protetice', pret: '300' },
  { name: 'Extractie implant osteointegrat', pret: '2500' },
  { name: 'Extracție implant cu periimmplantita', pret: '2000' },
  { name: 'Regularizare creasta osoasa', pret: '1500' },
  { name: 'Retainer post tratament ortodontic', pret: '400' },
  { name: 'Refacere aparat dentar mobil', pret: '200' },
  { name: 'Adaugare brackets/dinte', pret: '150' }
];
